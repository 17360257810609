/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useContext, useCallback } from 'react';
import moment from 'moment';

import { CircularProgress, Backdrop } from '@mui/material';
import { PreSendTransaction, TransactionStatus } from '@presend/verification-testing';
import Modal from '@mui/material/Modal';
import ThemeContext from '../../context/ThemeContext';
import { DISCORD_URL, TokenInfoType } from '../../constants';

import TokenTransfer from '../TokenTransfer';
import ProcessingModal from '../ProcessingModal';
import WarningModal, { WarningModalEnum } from '../WarningModal';
import DarkModeSwitch from '../Buttons/DarkModeSwitch';
import './dashboard.css';
import AccountWrapper from '../Cards/AccountWrapper';
import MainTokenWrapper from '../Cards/MainTokenWrapper';
import AssetsActivityWrapper, { ActivityProps } from '../Layouts/AssetsActivityWrapper';
import Footer from '../Cards/Footer';
import Header from '../Cards/Header';
import { Error } from '../../constants/tokenTransferErrors';

enum ActivitySortType {
  Timeline = 'timeline',
  Status = 'status'
}

enum ColorTheme {
  DARK = 'dark',
  LIGHT = 'light'
}

interface DashboardProps {
  loading: boolean;
  txResultStatus: boolean;
  txError: Error;
  loadingText: string;
  reload: boolean;
  txProcessing: boolean;
  amount: string;
  address: string;
  isVideoVisible: boolean;
  tokensInfo: Array<TokenInfoType>;
  customNftList: Array<TokenInfoType>;
  mainTokenInfo: TokenInfoType;
  selectedTokenInfo: TokenInfoType;
  chainId: string;
  exchange: string;
  walletType: string;
  modalStatus: boolean;
  warningModalStatus: boolean;
  walletAddress: string;
  usingExchange: string;
  cexList: Array<string>;
  tabValue: number;
  txHistoryList: Array<PreSendTransaction>;
  logOut: () => Promise<void>;
  transferERC20: (isTxRecovery: boolean) => Promise<void>;
  fillInputs: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  setAddress: (address: string) => void;
  setIsVideoVisible: (isVideoVisible: boolean) => void;
  handleChainId: (__chainId: string) => void;
  setExchange: (data: string) => void;
  setWalletType: (data: string) => void;
  handleCloseModal: (status: boolean) => void;
  setModalStatus: (status: boolean) => void;
  setWarningModalStatus: (status: boolean) => void;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  handleClose: () => void;
  openResumeTransactionModal: (transactionItem: ActivityProps) => void;
  isResumableTransactionModalVisible: boolean;
  setIsResumableTransactionModalVisible: (isResumableTransactionModalVisible: boolean) => void;
  nativeTokenSymbols: Array<string>;
  setTxError: (data: Error) => void;
  resumingTransaction: boolean;
  setResumingTransaction: (resuming: boolean) => void;
  warningModalEnum: WarningModalEnum;
}

function Dashboard({
  loading,
  txResultStatus,
  txError,
  loadingText,
  reload,
  txProcessing,
  amount,
  address,
  isVideoVisible,
  tokensInfo,
  customNftList,
  mainTokenInfo,
  selectedTokenInfo,
  chainId,
  exchange,
  walletType,
  modalStatus,
  warningModalStatus,
  walletAddress,
  usingExchange,
  cexList,
  tabValue,
  txHistoryList,
  logOut,
  transferERC20,
  fillInputs,
  setAmount,
  setAddress,
  setIsVideoVisible,
  handleChainId,
  setExchange,
  setWalletType,
  handleCloseModal,
  setModalStatus,
  setWarningModalStatus,
  setSelectedTokenInfo,
  handleChange,
  handleTabChange,
  handleClose,
  openResumeTransactionModal,
  isResumableTransactionModalVisible,
  setIsResumableTransactionModalVisible,
  nativeTokenSymbols,
  setTxError,
  resumingTransaction,
  setResumingTransaction,
  warningModalEnum
}: DashboardProps) {
  const [activeSwitch, setActiveSwitch] = useState<string>('timeline');
  const [filter, setFilter] = useState<string>('');
  const [desc, setDesc] = useState<boolean>(true);
  const [txHistories, setTxHistories] = useState<any>([]);
  const [filteredTxs, setFilteredTxs] = useState<any>([]);
  const { theme } = useContext(ThemeContext);
  const [isDark, setIsDark] = useState<boolean>(false);
  let logoImage;
  const handleSwitchValue = (e: string) => {
    setActiveSwitch(e);
  };

  const handleFilterValue = (e: string) => {
    setFilter(e);
  };

  const decideTheme = useCallback(() => {
    if (theme === ColorTheme.DARK) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [theme]);

  useEffect(() => {
    decideTheme();
  }, [theme, decideTheme]);

  if (isDark) {
    logoImage = (
      <img
        src="/images/logo-white.png"
        alt="logo-black"
        className="max-w-[60%] max-h-full self-center"
      />
    );
  } else {
    logoImage = (
      <img
        src="/images/logo-black.png"
        alt="logo-white"
        className="max-w-[60%] max-h-full self-center"
      />
    );
  }

  useEffect(() => {
    const tempTxHistories: PreSendTransaction[] = txHistoryList.map((history: any) => {
      console.log(history);
      let txType = '';
      if (history.status === TransactionStatus.Verified && !history.verificationPassed) {
        txType = 'saved';
      } else if (history.status !== TransactionStatus.Verified) {
        txType = 'incomplete';
      } else if (history.verificationPassed && history.status === TransactionStatus.Verified) {
        txType = 'protected';
      } else if (
        history.status === TransactionStatus.Paid ||
        history.status === TransactionStatus.Pending
      ) {
        txType = 'incomplete';
      }
      return { ...history, txType };
    });
    setTxHistories(tempTxHistories);
  }, [txHistoryList]);

  useEffect(() => {
    let filtered;
    let sortedTxHistories;
    if (desc) {
      sortedTxHistories = txHistories.sort((a: any, b: any) =>
        // eslint-disable-next-line prettier/prettier
        moment.utc(b.created).diff(moment.utc(a.created)));
    } else {
      sortedTxHistories = txHistories.sort(
        (a: any, b: any) => moment.utc(a.created).diff(moment.utc(b.created))
        // eslint-disable-next-line function-paren-newline
      );
    }

    if (activeSwitch === ActivitySortType.Timeline) {
      filtered = sortedTxHistories;
    } else if (activeSwitch === ActivitySortType.Status) {
      if (filter === 'saved') {
        filtered = sortedTxHistories.filter((history: any) => history.txType === 'saved');
      } else if (filter === 'protected') {
        filtered = sortedTxHistories.filter((history: any) => history.txType === 'protected');
      } else if (filter === 'incomplete') {
        filtered = sortedTxHistories.filter((history: any) => history.txType === 'incomplete');
      } else if (filter === 'canceled') {
        filtered = sortedTxHistories.filter((history: any) => history.txType === 'canceled');
      } else {
        filtered = sortedTxHistories;
      }
    }
    setFilteredTxs(filtered);
  }, [activeSwitch, filter, desc, txHistories]);

  return (
    <div className="dashboard">
      <div className="dashboard-body">
        <Header chainId={chainId} logOut={logOut} handleChainId={handleChainId} />
        <div className="main-content">
          <div className="dark-mode">
            <DarkModeSwitch />
          </div>
          <AccountWrapper walletAddress={walletAddress} />
          <MainTokenWrapper
            mainTokenInfo={mainTokenInfo}
            fillInputs={fillInputs}
            setSelectedTokenInfo={setSelectedTokenInfo}
            setAmount={setAmount}
            setModalStatus={setModalStatus}
            setResumingTransaction={setResumingTransaction}
          />
          <AssetsActivityWrapper
            tabValue={tabValue}
            isVideoVisible={isVideoVisible}
            customNftList={customNftList}
            setIsVideoVisible={setIsVideoVisible}
            handleTabChange={handleTabChange}
            tokensInfo={tokensInfo}
            setSelectedTokenInfo={setSelectedTokenInfo}
            fillInputs={fillInputs}
            setAmount={setAmount}
            setModalStatus={setModalStatus}
            handleSwitchValue={handleSwitchValue}
            handleFilterValue={handleFilterValue}
            desc={desc}
            setDesc={setDesc}
            activeSwitch={activeSwitch}
            filteredTxs={filteredTxs}
            walletAddress={walletAddress}
            ActivitySortType={ActivitySortType}
            openResumeTransactionModal={openResumeTransactionModal}
            setResumingTransaction={setResumingTransaction}
          />
        </div>
      </div>
      <Footer />
      <Modal
        open={modalStatus}
        onClose={handleCloseModal}
        style={{ borderRadius: '5px' }}
        id={theme}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: '10100 !important',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 72px)',
            maxWidth: '500px',
            height: 'auto',
            borderRadius: '12px',
            background: '#FFFFFF',
            outline: 'none',
            boxShadow:
              '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)'
          }}
        >
          <TokenTransfer
            amount={amount}
            address={address}
            selectedTokenInfo={selectedTokenInfo}
            tokensInfo={tokensInfo}
            exchange={exchange}
            usingExchange={usingExchange}
            walletType={walletType}
            setWalletType={setWalletType}
            cexList={cexList}
            txError={txError}
            setExchange={setExchange}
            handleChange={handleChange}
            setAmount={setAmount}
            setAddress={setAddress}
            transferERC20={transferERC20}
            setSelectedTokenInfo={setSelectedTokenInfo}
            nativeTokenSymbols={nativeTokenSymbols}
            setTxError={setTxError}
            resumingTransaction={resumingTransaction}
            setModalStatus={setModalStatus}
          />
        </div>
      </Modal>
      {loading && (
        <Backdrop sx={{ color: 'Grey', zIndex: (bTheme) => bTheme.zIndex.drawer + 1 }} open>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <CircularProgress sx={{ color: '#fff' }} />
            </div>
            <div style={{ color: '#fff' }}>Loading</div>
          </div>
        </Backdrop>
      )}
      <ProcessingModal
        text={loadingText}
        loading={txProcessing}
        txResultStatus={txResultStatus}
        handleClose={handleClose}
      />
      <WarningModal
        open={warningModalStatus}
        reload={reload}
        handleModal={setWarningModalStatus}
        id={warningModalEnum}
      />
      <Modal
        open={isVideoVisible}
        onClose={() => {
          setIsVideoVisible(!isVideoVisible);
        }}
        style={{ borderRadius: '5px' }}
        id={theme}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: '10100 !important',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            height: 'auto',
            borderRadius: '12px',
            outline: 'none',
            boxShadow:
              '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)'
          }}
        >
          <div>
            <iframe
              className="rounded-t-2xl border-t-2 border-x-2 border-white"
              width="300"
              height="400"
              title="PreSend - How To Use"
              allow="autoplay; encrypted-media"
              allowFullScreen
              src="https://www.youtube.com/embed/rbqcOBK2PRw"
            />
            <div className="dark:text-white dark:bg-black rounded-b-2xl border-t">
              <div className="p-2 text-center">
                <p className="">Still not sure on how to use the extension?</p>
                <a
                  className="text-[#87A4D1] no-underline"
                  href={DISCORD_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact PreSend Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isResumableTransactionModalVisible}
        onClose={() => {
          setIsResumableTransactionModalVisible(!isResumableTransactionModalVisible);
        }}
        style={{ borderRadius: '5px' }}
        id={theme}
      >
        <div className="resume-transaction-modal">
          <div>
            <div className="dark:text-white dark:bg-black rounded-2xl border">
              <div className="p-2 text-center">
                <p className="text-xl">Resume Transaction</p>
                <p className="pt-2">Do you wish to resume this transaction?</p>
                <div className="grid grid-cols-2 pt-4 gap-3">
                  <button
                    type="button"
                    className={`w-full h-[33px] rounded-[999px] ${isDark ? 'bg-white' : 'bg-black'}  flex justify-center border:black shadow-[0px_0px_1px_1px_rgb(0,0,0)] hover:border hover:border-black hover:shadow-[0px_0px_2px_2px_rgb(0,0,0)] dark:hover:border-white dark:bg-white dark:hover:shadow-[0px_0px_4px_3px_rgb(128,128,128)]`}
                    onClick={() => transferERC20(true)}
                  >
                    {logoImage}
                  </button>
                  <button
                    className="w-full h-[33px] rounded-[999px] justify-center border-white border flex items-center shadow-[0px_0px_1px_1px_rgb(0,0,0)] hover:shadow-[0px_0px_2px_2px_rgb(0,0,0)] dark:hover:border dark:hover:border-white dark:hover:shadow-[0px_0px_4px_3px_rgb(128,128,128)]"
                    type="button"
                    onClick={() => {
                      setIsResumableTransactionModalVisible(!isResumableTransactionModalVisible);
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
