/* eslint-disable react/jsx-props-no-spreading */

import { Tabs, Tab, Box, Typography } from '@mui/material';
import { PreSendTransaction } from '@presend/verification-testing';
import { TokenInfoType } from '../../../constants';
import HowToUse from '../../Cards/HowToUse';
import AssetsWrapper from '../AssetsWrapper';
import ActivityWrapper from '../ActivityWrapper';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}
export interface ActivityProps extends PreSendTransaction {
  txType: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface AssetsActivityWrapperProps {
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabValue: number;
  isVideoVisible: boolean;
  customNftList: Array<TokenInfoType>;
  setIsVideoVisible: (isVideoVisible: boolean) => void;
  tokensInfo: Array<TokenInfoType>;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  fillInputs: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  setModalStatus: (status: boolean) => void;
  handleSwitchValue: (value: string) => void;
  handleFilterValue: (value: string) => void;
  desc: boolean;
  setDesc: (value: boolean) => void;
  activeSwitch: string;
  filteredTxs: any[];
  walletAddress: string;
  ActivitySortType: any;
  openResumeTransactionModal: (transactionItem: ActivityProps) => void;
  setResumingTransaction: (resuming: boolean) => void;
}

function AssetsActivityWrapper({
  handleTabChange,
  tabValue,
  isVideoVisible,
  customNftList,
  setIsVideoVisible,
  tokensInfo,
  setSelectedTokenInfo,
  fillInputs,
  setAmount,
  setModalStatus,
  handleSwitchValue,
  handleFilterValue,
  desc,
  setDesc,
  activeSwitch,
  filteredTxs,
  walletAddress,
  ActivitySortType,
  openResumeTransactionModal,
  setResumingTransaction
}: AssetsActivityWrapperProps) {
  return (
    <div className="col-md-12 assets-activity-wrapper">
      <div className="divider" />
      <Box sx={{ width: '100%', minHeight: 200 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                background: '#43B649',
                height: '3px',
                marginBottom: '4px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              },
              '& .MuiTab-root': {
                width: '50%',
                fontSize: '14px',
                color: '#6C757D',
                fontWeight: '400',
                paddingBottom: '4px',
                bottom: '-3px',
                textTransform: 'capitalize'
              },
              '& .Mui-selected': {
                color: '#43B649 !important'
              }
            }}
          >
            <Tab className="dark:text-white" label="Assets" {...a11yProps(0)} />
            <Tab className="dark:text-white" label="Activity" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <AssetsWrapper
            tokensInfo={tokensInfo}
            setSelectedTokenInfo={setSelectedTokenInfo}
            fillInputs={fillInputs}
            setAmount={setAmount}
            setModalStatus={setModalStatus}
            customNftList={customNftList}
            setResumingTransaction={setResumingTransaction}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ActivityWrapper
            walletAddress={walletAddress}
            handleSwitchValue={handleSwitchValue}
            handleFilterValue={handleFilterValue}
            activeSwitch={activeSwitch}
            desc={desc}
            setDesc={setDesc}
            filteredTxs={filteredTxs}
            ActivitySortType={ActivitySortType}
            openResumeTransactionModal={openResumeTransactionModal}
          />
        </TabPanel>
      </Box>
      <HowToUse isVideoVisible={isVideoVisible} setIsVideoVisible={setIsVideoVisible} />
    </div>
  );
}

export default AssetsActivityWrapper;
